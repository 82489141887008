import { Component, createRef } from "react"

// openlayers 
import { Map, Feature } from "ol"
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import { Vector as VectorLayer } from "ol/layer";
import { fromLonLat } from "ol/proj";
import { Style, Icon } from 'ol/style';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import OSM from "ol/source/OSM";

// Local 
import marker from '../../images/Flag.png';
import "./Map.css";

class MapWrapper extends Component {
    constructor(props) {
        super(props)
        this.canvas = new Map({
        target: this.ref,
        layers: [
            new TileLayer({
            source: new OSM()
            }),
            new VectorLayer({
              properties: { name: 'existingMarkers' },
              source: new VectorSource({
                features: [
                  new Feature({
                    geometry: new Point(fromLonLat([18.0498969, 59.3428621])),
                    name: '',
                  })
                ],
              }),
              style: new Style({
                image: new Icon({
                  anchor: [0.5, 1],
                  src: marker,
                  scale: 0.04
                })
              })
            }),
        ],
        view: new View({
            center: fromLonLat([18.0497964, 59.3429621]),
            maxZoom: 20,
            zoom: 16
            })
        })
        this.ref = createRef()
    }

    componentDidMount() {
        this.canvas.setTarget(this.ref.current)
    }    

    render() {
        return (
            <div ref={this.ref} className='map'></div>
        )
    }
}

export default MapWrapper