import '../App.css';
import qr from '../images/QR.png';

export default function Introduction () {

  return (
    <div className='treatment-page  grid grid-cols-1 gap-4 place-content-center relative m-10 p-10' id='introduction'>
        <div className="text-scale-xl text-white">A one-of-a-kind journey</div>
        <div className='text-scale-md info-text text-white'> <p> It's not just your ordinary running experience - it's a unique concept that combines the beauty of nature with reflective inquiries in a group setting. Imagine feeling the wind on your face as you stride through picturesque trails, and then pausing to reflect, connect, and energize with like-minded individuals.</p>

<p className="mt-4">REFLECT RUN is more than just a run, it's an opportunity to nourish your body, mind, and soul. It's a chance to delve deeper into your thoughts, gain insights, and tap into your inner strength. Together, we'll thrive as we support and uplift each other on this incredible journey.
</p>
<p className="mt-4">So, lace up your running shoes and get ready to embark on an adventure that will leave you feeling connected, inspired, and rejuvenated. Join us in the beauty of nature, as we reflect, run, and prosper together. Your path to holistic well-being starts here with REFLECT RUN. Let's create unforgettable memories and elevate our running experience to new heights! 🙏 </p>
        </div>
        <div className="">
          <a href="https://chat.whatsapp.com/HJPDwchgLUVHCdeteVYcan">
            <img src={qr} className='qr' alt="https://chat.whatsapp.com/HJPDwchgLUVHCdeteVYcan"/>
          </a>
        </div>
    </div>
  )
}