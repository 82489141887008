import '../App.css';
import "ol/ol.css";
import MapWrapper from './Map/MapWrapper'
import  './Map/Map.css'

export default function About () {

  return (
    <div className='about' id='about'>
      <div className='grid grid-cols-1 gap-8'>
        <div className='text-white pt-20 pb-16 text-scale-2xl'>
          We start at Odenplan every Sunday 11am <br />
          <h3 className='info-text pt-6 text-scale-md'>Bring your loved ones ❤️🏃🏼‍♂️</h3>
        </div>
        <div className='map-container'>
          <MapWrapper />  
        </div>
      </div>
    </div>
  )
}