import '../App.css';
 
export default function Footer() {

  return (
  <footer className="bg-neutral-800 text-white ">
    <div className="container py-5 ">
      <div className="row py-4">
        <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <p className="font-italic text-muted info-text">Stay ahead of the game by joining us on social media to receive real-time updates on all our exciting activities!</p>
          <ul className="list-inline mt-4">
            <li className="list-inline-item">
                <a href="https://chat.whatsapp.com/HJPDwchgLUVHCdeteVYcan" target="_blank" rel="noreferrer" title="facebook">
                    <i className="gg-facebook"></i>
                    </a></li>
            <li className="list-inline-item">
                <a href="https://www.instagram.com/reflectrunsweden/" target="_blank" rel="noreferrer" title="instagram">
                    <i className="gg-instagram"></i>
                    </a></li>
          </ul>
        </div>
        <div className="col-lg-2 col-md-6 mb-4 mb-lg-0">
          <h6 className="text-uppercase font-weight-bold mb-4">Kontakt</h6>
          <ul className="list-unstyled mb-0">
            <li className="mb-2 text-muted info-text hover-gold">Neo & Tom</li>
            <li className="mb-2 text-muted info-text">
                <a className="hover-gold" href="tel:+4672-1505363">
                    +46 76-879 90 58
                </a>
            </li>
            <li className="mb-2 text-muted info-text">
                <a className="hover-gold" href="mailto:info@reflectrun.com?Subject=Jag hittade er via hemsidan" rel="noreferrer" target="_blank">info@reflectrun.com</a>
            </li>
            <li className="mb-2 text-muted info-text">
                <a className="hover-gold" href="https://www.google.com/maps/place/Serafimergr%C3%A4nd+15,+112+24+Stockholm/data=!4m2!3m1!1s0x465f9d61d89ecaa3:0xa5570dbd1f160e7b?sa=X&ved=2ahUKEwjTssOaqaH8AhUOx4sKHR41AJgQ8gF6BAgZEAE" rel="noreferrer" target="_blank"> Odenplan <br /> 113 22 Stockholm
                </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    {/* <!-- Copyrights --> */}
    <div className="bg-light py-2">
      <div className="container text-center">
        <p className="text-muted info-text mb-0 py-2">© 2023 Tom Axberg</p>
      </div>
    </div>
  </footer>
  )
}