import Introduction from './Introduction';
import Landing from './Landing';
import Navigationbar  from './Navigationbar';
import About from './About';
import Footer from './Footer';
import '../App.css';

export default function App() {
  return (
    <div className="App">
      <Navigationbar />
      <Landing />
      <Introduction />
      <div className="Room scroll-auto max-sm:hidden z-20 " alt="room" />
      <div className="room sm:hidden z-20 " alt="room" />
      <About />
      <Footer />
    </div>
  );
}
