import { useState, useEffect } from "react";
import '../App.css';
import logo from '../logos/RR-logo.svg';

export default function Landing () {
  
  const [isVisible, setIsVisible] = useState(true);
  
  useEffect(() => {   
    window.addEventListener("scroll", listenToScroll);
    return () => 
       window.removeEventListener("scroll", listenToScroll); 
  })

  const listenToScroll = () => {
    let heightToHideFrom = 200;
    const winScroll = document.body.scrollTop || 
        document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {  
         isVisible && setIsVisible(false);
    } else {
         setIsVisible(true);
    }  
  };

  return (
    <div className='Landing-page grid grid-cols-1 gap-4 place-content-center text-white' id='home'>
        <img src={logo} className="axum-logo" alt="logo" />
        <div className="text-scale-xl">Say hi to reflective training</div>
        <div className='text-scale-md info-text'>Group reflective inquiries designed to help <br /> you feel connected and energized ⚡️<br />  </div>
        <div className='grid grid-cols-2'>
        {isVisible && <div className='hide arrow bounce z-20'></div>}
        </div>
    </div>
  )
}