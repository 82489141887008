import React, { useEffect } from "react";
import Navbar from 'react-bootstrap/Navbar';


function Navigationbar() {

  useEffect(() => {
    let url = window.location.href.split("/");
    let target = url[url.length - 1].toLowerCase();
    let element = document.getElementById(target);
    element && element.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  return (
    <Navbar fixed="top">
        <header className='grid grid-cols-1 gap-4 place-content-center text-white'>
            <nav>
                <ul >
                    <li><a 
                      className="nav-text" 
                      href="/"         
                      onClick={e => {
                      let hero = document.getElementById("introduction");
                      e.preventDefault();
                      hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });
                      window.history.pushState("introduction", "introduction", "/introduction");
                      }}
                      >Introduction
                    </a></li>
                    <li><a 
                      className="nav-text" 
                      href="/"         
                      onClick={e => {
                      let hero = document.getElementById("terapeuts");
                      e.preventDefault();
                      hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });
                      window.history.pushState("terapeuts", "terapeuts", "/terapeuts");
                      }}
                      >Method</a></li>
                    <li><a 
                      className="nav-text" 
                      href="/"         
                      onClick={e => {
                      let hero = document.getElementById("about");
                      e.preventDefault();
                      hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });
                      window.history.pushState("about", "about", "/about");
                      }}
                      >About</a></li>
                </ul>
            </nav>
        </header>
    </Navbar>
  );
}

export default Navigationbar;